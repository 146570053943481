import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';

@Injectable()
export class CedulaService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }
    
    getAll(idPerson: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/cedula/getAll/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/getAll")));
    }
    
    getById(idPerson,idCedula): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/cedula/filter/${idPerson}/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/getById")));
    }

    create(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/create")));
    }

    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/update")));
    }
    
    anular(idCedula: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/cedula/anular/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }

    createCedulaTmp(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/createCedulaTmp`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/createCedulaTmp")));
    }
    
    updateCedulaTmp(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/updateCedulaTmp`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/updateCedulaTmp")));
    }
    
    buscarCedulaTmp(rucName: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const rucFilter = rucName == '' ? undefined : rucName;
        return this.http.get(`${environment.baseUrl}/cedula/buscarCedulaTmp/${rucFilter}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedulaName")));
    }
    
    public upload(formData) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/files`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    } 
}