import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';
import { Page } from 'src/app/core/helpers/page';

@Injectable()
export class ContactService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }
    
    getAll(idPerson: number, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //return this.http.get(`${environment.baseUrl}/contact/getAll/${idPerson}`, { headers: headers })
        //return this.http.get(`${environment.baseUrl}/contact/getAll/${idPerson}/${limit}`, { headers: headers })
        //.pipe(catchError(this.handler.handleError<[]>("contact/getAll")));
        return this.http.get(`${environment.baseUrl}/contact/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getAll")));
    }
    
    getById(idPerson,idContact): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/${idPerson}/${idContact}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getById")));
    }
    
    buscar(idPerson: number, ruc: string, opcion: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/buscar/${idPerson}/${ruc}/${opcion}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contactName")));
    }
    
    buscarCode(idPerson: number, code: string,  opcion: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/buscarCode/${idPerson}/${code}/${opcion}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contactCode")));
    }

    crear(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/contact/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/create")));
    }
    
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/contact/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/update")));
    }
    
    anular(idContact: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/contact/anular/${idContact}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }
    
    getList(idPerson: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/getList/${idPerson} `, { headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getList")));
    }
}