import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';
import { Page } from '../../core/helpers/page';

@Injectable()
export class CreditService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    public pathArchivos: string;
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) {
        this.pathArchivos = environment.baseUrl;
     }

    getById(idPerson: number, id: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/creditRequest/getById/${idPerson}/${id}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("credit")));
    }

    getAllParam(idPerson: number, desdeFecha, hastaFecha, page: Page): Observable<any> {
        
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/credit-request/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getAll")));
    }

    getAll(idPerson: number, desdeFecha, hastaFecha, page: Page, estado: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}&_estado=${estado}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getAll")));
    }

    getAllCedula(idPerson: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getAllCedula/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getAllCedula")));
    }

    getByIdPerson(idPerson: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/document/person/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("person")));
    }
    
    getByIdCedula(idPerson: number, idCedula: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/creditRequest/getByIdCedula/${idPerson}/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("cedula")));
    }

    addOrUpdate(entity): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<Response>(`${environment.baseUrl}/creditRequest/addOrUpdate`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("mantenerSolicitud")));
    }

    /*getRequestNro(): Observable<Response> {
        console.log("frontend service getRequestNro - url:", `${environment.baseUrl}/creditRequest/getRequestNro`);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/creditRequest/getRequestNro`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("requestNro")));
    }*/
    
   // lleva parametro sin uso 
   leerNumero(): Observable<any> {
        //console.log("frontend service leerNumero - url:", `${environment.baseUrl}/creditRequest/leerNumero/1`);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/leerNumero/1`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("leerNumero")));
    }
    
    // lleva parametro sin uso 
    getDestination(): Observable<any> {
        //console.log("frontend service getDestination - url:", `${environment.baseUrl}/creditRequest/getDestination/1`);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getDestination/1`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("destination")));
    }
    
    /*getDestination(): Observable<Response> {
        console.log("frontend service getDestination - url:", `${environment.baseUrl}/creditRequest/getDestination`);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/creditRequest/getDestination`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("destination")));
    }*/
    
    getCedula(): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get<Response>(`${environment.baseUrl}/creditRequest/getCedula`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("cedula")));
    }

    anular(idCreditRequest: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/creditRequest/anular/${idCreditRequest}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }
    
    anularCedula(idCedula: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/creditRequest/anularCedula/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }

    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/creditRequest/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/update")));
    }
    
    updateCedula(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/creditRequest/updateCedula`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/updateCedula")));
    }

    public upload(formData) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/files`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    } 
    
    getList(idPerson: number, desdeFecha, hastaFecha, page: Page, estado: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getList/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}&_estado=${estado}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getList")));
    }

}