import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Page } from '../../core/helpers/page';

@Injectable()
export class CreditService {

    private handler: HttpErrorHandler = new HttpErrorHandler();
    public pathArchivos: string;

    constructor(
        public router: Router,
        public http: HttpClient,
    ) { 
        this.pathArchivos = environment.baseUrl;
    }

    getAllPaginado(idPerson: number, desdeFecha, hastaFecha, page: Page, estado: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getAllPaginado/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}&_estado=${estado}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getAllPaginado")));
    }
    
    getList(idPerson: number, desdeFecha, hastaFecha, page: Page, estado: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getList/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}&_estado=${estado}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getList")));
    }

    getAll(idPerson: number, limit: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getAll/${idPerson}/${limit}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getAll")));
    }
    
    updateEstado(idCreditRequest: number, estado: string, observation: string ): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/creditRequest/updateEstado/${idCreditRequest}/${estado}/${observation}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("estado")));
    }
    
    updateBloqueo(idPerson: number, idCreditRequest: number, requestBlocked: boolean ): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/creditRequest/updateBloqueo/${idPerson}/${idCreditRequest}/${requestBlocked}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("bloqueo")));
    }

}
