export * from './client.service';
export * from './contact.service';
export * from './cedula.service';
export * from './credit.service';
export * from './config.service';
export * from './dashboard.service';
export * from './suscription.service';
export * from './../../account/services/user.service';
export * from './../../admsystem/services/usuario.service';
//export * from './../../admsystem/services/coments.service';
export * from './coments.service';
export * from './location.service';
export * from './support.service';