import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Page } from '../../core/helpers/page';

@Injectable()
export class LocationService {

    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }

    getAll(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/location/getAll`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("location")));
    }
    
    getAllPaginado(page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/location/getAllPaginado?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("location/getAll")));
    }

    create(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/location/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("location/create")));
    }
     
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/location/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("location/update")));
    }

    anular(idLocation: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/location/anular/${idLocation}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }

    buscar(name: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/location/buscar/${name}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("locationName")));
    }

}