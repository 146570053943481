import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';

@Injectable()
export class SupportService {

    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }
    
    getById(idPerson, idSupportData): Observable<any> {
        //console.log("frontend getById: ", idSupportData);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/support-data/${idPerson}/${idSupportData}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("support-data/getById")));
    }

    create(entity): Observable<any> {
        //console.log("frontend create: ",entity);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/support-data/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("support-data/create")));
    }
     
    update(entity): Observable<any> {
        //console.log("frontend update: ",entity);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/support-data/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("support-data/update")));
    }

    anular(idSupportData: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/support-data/anular/${idSupportData}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }
    
    buscar(name: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/support-data/buscar`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("support-data")));
    }
    
    public upload(formData) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/files`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
    
    public getUrlFile() {
        return environment.baseUrl;
    }  
}