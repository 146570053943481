import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Page } from '../../core/helpers/page';

@Injectable()
export class ClienteService {

    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }

    getAll(idPerson, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/person/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("login")));
    }
    
    getPerson(idPerson): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/person/getPerson/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("person")));
    }

}