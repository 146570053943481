import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';
import { Page } from '../../core/helpers/page';

@Injectable()
export class UsuarioService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }
    
    getAllPaginado(idPerson, page: Page, inactivo: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/user/getAllPaginado/${idPerson}/${inactivo}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/getAll")));
    }
    
    getAll(idPerson, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/user/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/getAll")));
    }

    getById(idPerson,idUser): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/user/${idPerson}/${idUser}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/getById")));
    }
    
    getList(idPerson): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/user/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/getList")));
    }
    
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/update")));
    }
  // crear(entity): Observable<any> {
    createUsuario(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/createUsuario`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/createUsuario")));
    }

    updateUsuario(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/updateUsuario`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/updateUsuario")));
    }
    
    updateEstado(status, entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/updateUsuario`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/updateUsuario")));
    }

    anular(idUser: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/user/anular/${idUser}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }
    
    ocultar(idUser: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/user/ocultar/${idUser}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("ocultar")));
    }

    recuperar(idUser: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/recuperar/${idUser}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("recuperar")));
    }
}