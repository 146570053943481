<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Desarrollado por Tedalab
            </div>
            <div class="col-sm-6">

                <div class="online-advisor">
                    <button class="advisor-btn" (click)="toggleDropdown()">
                        <i class="mdi mdi-whatsapp"></i>
                        Asesor Online
                    </button>
                    <div class="dropdown" *ngIf="dropdownVisible">
                        <div class="advisor-header">
                            <span>Soporte / Vendedores</span>
                            <span class="fa fa-times close-icon" (click)="toggleDropdown()" role="button" aria-label="Cerrar"></span>
                        </div>

                        <div class="advisor-content">
                            <ul class="advisor-list">
                                <li *ngFor="let row of dashboardInfo?.support; let i = index" class="advisor-item">
                                   <a href="https://wa.me/{{editarPhone(row.phone)}}/?text=Hola%20,%20en%20que%20podemos%20ayudarle" target="_blank">
                                        <div class="advisor-info" style="border-bottom: 1px solid #ccc;">
                                            <img [src]="pathArchivos+'/files/'+row.pathImagen" alt="Foto" class="profile-pic">
                                            <div class="advisor-details">
                                                <div class="name">{{ row.name }}</div>
                                                <div class="cargo">{{ row.cargo }}</div>
                                                <div class="title">{{editarPhoneMask(row.phone) }}</div>
                                                
                                            </div>
                                        </div>
                                    </a> 
    
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>