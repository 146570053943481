export const environment = {
  production: false,
  //production: true,
  baseUrl: 'https://allcredi.tedalab.com/api',
  //  baseUrl: 'http://localhost:3500',
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
