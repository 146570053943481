import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Response } from '../../core/models/response.model';
import { Page } from '../../core/helpers/page';

@Injectable()
export class CedulaService {
   
    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    public pathArchivos: string;

    constructor(
        public router: Router,
        public http: HttpClient,
    ) { 
        this.pathArchivos = environment.baseUrl;
    }
    
    getAll(idPerson, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/cedula/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/getAll")));
    }
 
    getAllPaginadoTmp(page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/cedula/getAllPaginadoTmp?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/getAll")));
    }

    getById(idPerson,idCedula): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/cedula/filter/${idPerson}/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/getById")));
    }

    create(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/create")));
    }
    
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/update")));
    }
    
    anular(idCedula: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/cedula/anular/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }
    
    anularTmp(idCedula: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/cedula/anularTmp/${idCedula}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anularTmp")));
    }

    buscar(ruc: string, name: string, idPerson: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const rucFilter = ruc == '' ? undefined : ruc;
        const nameFilter = name == '' ? undefined : name;
        return this.http.get(`${environment.baseUrl}/contact/buscar/${rucFilter}/${nameFilter}/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedulaName")));
    }


    /**
     * Buscar empresa
     * @param rucName 
     * @returns 
     */
    buscarEmpresa(rucName: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const rucFilter = rucName == '' ? undefined : rucName;
        return this.http.get(`${environment.baseUrl}/cedula/buscarEmpresa/${rucFilter}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedulaName")));
    }

    /**
     * Crear empresa
     * @param entity 
     * @returns 
     */
    createEmpresa(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/createEmpresa`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/createEmpresa")));
    }
    
    /**
     * Actualizar empresa
     * @param entity 
     * @returns 
     */
    updateEmpresa(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/cedula/updateEmpresa`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedula/updateEmpresa")));
    }
    
}