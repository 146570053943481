import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Page } from '../../core/helpers/page';

@Injectable()
export class ContactService {

    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }

    getAll(idPerson: number, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //return this.http.get(`${environment.baseUrl}/contact/getAll/${idPerson}`, { headers: headers })
        //.pipe(catchError(this.handler.handleError<[]>("contact/idPerson")));
        return this.http.get(`${environment.baseUrl}/contact/getAll/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getAll")));
    }
    
    /*getList(idPerson: number, desdeFecha, hastaFecha, page: Page, estado: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/creditRequest/getList/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}&_desdeFecha=${desdeFecha}&_hastaFecha=${hastaFecha}&_estado=${estado}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("creditRequest/getList")));
    }*/

    getAllPaginado(idPerson, page: Page): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/getAllPaginado/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getAll")));
    }
    
    getList(idPerson): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/getList")));
    }

    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/contact/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/update")));
    }
    
    updateShort(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/contact/updateShort`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contact/updateShort")));
    }

    anular(idContact: number): Observable<Response> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.delete<Response>(`${environment.baseUrl}/contact/anular/${idContact}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<Response>("anular")));
    }

    buscar(idPerson: number, ruc: string, opcion: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/contact/buscar/${idPerson}/${ruc}/${opcion}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("contactName")));
    }

    buscarEmpresa(rucName: string, idPerson: number): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        const rucFilter = rucName == '' ? undefined : rucName;
        return this.http.get(`${environment.baseUrl}/contact/buscar/${idPerson}/${rucFilter}/2`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("cedulaName")));
    }
   
}