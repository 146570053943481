import { Injectable } from '@angular/core';
//import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Page } from 'src/app/core/helpers/page';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { ConfigDto } from '../../admsystem/model/config.dto';
import { Router } from '@angular/router';

@Injectable()
export class ConfigService {

  //private loading = new BehaviorSubject<boolean>(false);
  public token: string;
  handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }

  getList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.baseUrl}/configuration/getList`, { headers: headers })
    .pipe(catchError(this.handler.handleError<[]>("/configuration/getList")));
  }

  create(entity: ConfigDto): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.baseUrl}/configuration/update`, JSON.stringify(entity), { headers: headers })
    .pipe(catchError(this.handler.handleError<[]>("configuration/update")));
  }

  update(entity): Observable<any> {
    console.log('entity forntend: ',entity);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.baseUrl}/configuration/update`, JSON.stringify(entity), { headers: headers })
    .pipe(catchError(this.handler.handleError<[]>("configuration/update")));
  }
 
  getConfiguration(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.baseUrl}/configuration`, { headers: headers })
    .pipe(catchError(this.handler.handleError<[]>("configuration")));
  }

}
