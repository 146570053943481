import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/admsystem/services';
import { DashboardService, UserService } from 'src/app/clientes/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [DashboardService, SupportService],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();

  currentUser = null;
  dropdownVisible: boolean = false;
  dashboardInfo: any;
  public pathArchivos: string;
  
  constructor(
    private dashboardService: DashboardService,
    public userService: UserService,
    public supportService: SupportService,

  ) { 
    this.currentUser = this.userService.getCurrentUser();
    //this.pathArchivos = this.userService.pathArchivos;
    this.pathArchivos = this.supportService.getUrlFile();
    console.log('this.pathArchivos ', this.pathArchivos );
    this.loadData();
  }
    
  loadData() {
    this.dashboardService.getAll(this.currentUser.idPerson, 20).subscribe((response) => {
      if (response.status) {
        this.dashboardInfo = response.data;
        console.log('this.dashboardInfo ', this.dashboardInfo );
      }
    }, error => {
      console.log("error", error);
    });
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }


  ngOnInit() {

  }
  
  editarPhone(phone){
    let texto = phone.replace(/\-/g, '');//
    let parte1 = texto.slice(1,4);
    let parte2 = texto.slice(4,7);
    let parte3 = texto.slice(7,12);
    let prefijo = '595';
    let result = `${prefijo}${parte1}${parte2}${parte3}`;
    result = result.replace(/\' '/g, '');
    return result;
  }
  
  editarPhoneMask(phone){
    let texto = phone.replace(/\-/g, '');//
    let parte1 = texto.slice(0,4);
    let parte2 = texto.slice(4,7);
    let parte3 = texto.slice(7,12);
    let result = `(${parte1}) ${parte2}-${parte3}`;
    console.log("textoAjustado:", result);
    return result;
  }

}
