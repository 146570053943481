import { MenuItem } from './menu.model';

export const MENU_ITEM: MenuItem[] = [
    { id: 1, label: 'MENUITEMS.MENU.TEXT', isTitle: true },
]

export const MENU_CLIENT: MenuItem[] = [
    { id: 130, label: 'Vendedores'+ ``, isTitle: true },
    { id: 131, label: 'Dashboard', icon: 'bx-home-circle', link: '/clientes', },
    { id: 132, label: 'Nueva Solicitud', icon: 'bx-edit', link: '/clientes/nuevo-credito', },
    { id: 133, label: 'Mis Solicitudes', icon: 'bx-briefcase-alt-2', link: '/clientes/mis-creditos', },
    { id: 134, label: 'Todas las Solicitudes', icon: 'bx-briefcase-alt-2', link: '/clientes/todos-creditos', },
    { id: 135, label: 'Mis Clientes', icon: 'bxs-user-detail', link: '/clientes/mis-contactos', },
    { id: 136, label: 'Enviar Comentario', icon: 'bx-edit', link: '/clientes/nuevo-comentario', },
    //{ id: 137, label: 'Todas las Ciudades', icon: 'bx-edit', link: '/clientes/mis-ciudades', },
    //{ id: 137, label: 'Mis Empresas', icon: 'bxs-user-detail', link: '/clientes/mis-empresas', },
    { id: 137, label: 'Registro Cédula', icon: 'bxs-user-detail', link: '/clientes/cedula-tmp', },
    
];

export const MENU_CREDIT: MenuItem[] = [
    { id: 140, label: 'Análista de Crédito', isTitle: true },
    { id: 141, label: 'Dashboard', icon: 'bx-home-circle', link: '/admsystem', },
    { id: 143, label: 'Solicitudes', icon: 'bx-briefcase-alt-2', link: '/admsystem/solicitudes', },
    //{ id: 145, label: 'Empresas', icon: 'bx-building', link: '/admsystem/empresas', },
    //{ id: 146, label: 'Ciudades', icon: 'bx-building', link: '/admsystem/ciudades', },
    { id: 147, label: 'Clientes', icon: 'bx-card', link: '/admsystem/contactos', },
    { id: 150, label: 'Comentarios', icon: 'bx-comment', link: '/admsystem/comentarios', },
];

export const MENU_ADMIN: MenuItem[] = [
    { id: 140, label: 'Administración', isTitle: true },
    { id: 141, label: 'Dashboard', icon: 'bx-home-circle', link: '/admsystem', },
    { id: 143, label: 'Solicitudes', icon: 'bx-briefcase-alt-2', link: '/admsystem/solicitudes', },
    { id: 145, label: 'Empresas', icon: 'bx-building', link: '/admsystem/empresas', },
    { id: 146, label: 'Ciudades', icon: 'bx-building', link: '/admsystem/ciudades', },
    { id: 147, label: 'Clientes', icon: 'bx-card', link: '/admsystem/contactos', },
    // { id: 142, label: 'Analista de Crédito', icon: 'bxs-user-check', link: '/admsystem/clientes', },
    { id: 149, label: 'Usuarios', icon: 'bxs-user-detail', link: '/admsystem/usuarios', },
    { id: 150, label: 'Comentarios', icon: 'bx-comment', link: '/admsystem/comentarios', },
    { id: 160, label: 'Configuración', icon: 'bx-home-circle', link: '/admsystem/config', },
    { id: 146, label: 'Aprobación Cédula', icon: 'bx-building', link: '/admsystem/aprobacion', },
    { id: 147, label: 'Soporte', icon: 'bxs-user-detail', link: '/admsystem/support-data', },
        
];