import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';
import { Page } from 'src/app/core/helpers/page';

@Injectable()
export class ComentsService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }
    
/*    getAll(idPerson): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/coments/getAll/${idPerson}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("getComents")));
    }
  */  
    getAllPaginado(idPerson: number,  page: Page): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/coments/getAllPaginado/${idPerson}?_page=${(page.pageNumber + 1)}&_limit=${page.size}&_searchText=${page.search}`, { headers })
        .pipe(catchError(this.handler.handleError<[]>("coments/getAllPaginado")));
    }
    

    getById(id): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(`${environment.baseUrl}/coments/${id}`, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("coments/getById")));
    }

    crear(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/coments/create`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("coments/create")));
    }
 
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/coments/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("coments/update")));
    }
}